<template>
  <div class="w-full max-w-6xl mx-auto px-6 md:px-12 pt-24 relative">
    <div
      class="fixed inset-0 bg-cover bg-center h-screen z-0"
    ></div>
    <!-- :style="`background-image: url(${require(`@/assets/backgrounds/bg-5.jpg`)});`" -->
    <div class="z-10 relative">
      <h1 class="text-center text-3xl font-written">Recepten</h1>

      <div class="flex justify-center mt-4 px-4">
        <div class="rounded-lg bg-white focus-within:border-primary border py-1">
          <span class="px-2">
            <font-awesome-icon icon="search" />
          </span>
          <input type="text" v-model="filter" class="rounded-r-lg px-1 focus:outline-none" placeholder="Zoek recept">
        </div>
      </div>

      <div class="flex justify-center items-center mt-4">
        <input type="checkbox" :checked="showNutrition" @input="toggleNutrition" class="mr-2">
        <label for="">toon voedingsinformatie</label>
      </div>

      <div v-if="showNutrition" class="flex justify-center md:mt-2">
        <div class="flex flex-wrap justify-center">
          <div class="mx-2 flex items-center">
            <!-- <input type="checkbox" v-model="filters.meat"> -->
            <font-awesome-icon :icon="['fas', 'drumstick-bite']" class="text-gray-700 text-xs md:text-sm mx-2"/>
            vlees
          </div>
          <div class="mx-2 flex items-center">
            <!-- <input type="checkbox" v-model="filters.fish"> -->
            <font-awesome-icon :icon="['fas', 'fish']" class="text-gray-700 text-xs md:text-sm mx-2"/>
            vis
          </div>
          <div class="mx-2 flex items-center">
            <!-- <input type="checkbox" v-model="filters.veggie"> -->
            <font-awesome-icon :icon="['fas', 'carrot']" class="text-gray-700 text-xs md:text-sm mx-2"/>
            vegetarisch
          </div>
          <div class="mx-2 flex items-center">
            <!-- <input type="checkbox" v-model="filters.lactose"> -->
            <font-awesome-icon :icon="['fas', 'cheese']" class="text-gray-700 text-xs md:text-sm mx-2"/>
            lactose
          </div>
          <div class="mx-2 flex items-center">
            <!-- <input type="checkbox" v-model="filters.gluten"> -->
            <font-awesome-icon :icon="['fas', 'bread-slice']" class="text-gray-700 text-xs md:text-sm mx-2"/>
            gluten
          </div>
        </div>
      </div>

      <div class="flex flex-wrap mt-20 lg:mt-4">
        <RecipeTile
          v-for="(recipe, index) in filtered" :key="recipe.id"
          class="w-1/2 sm:w-1/3 lg:w-1/4"
          :class="{
            '-mt-20 mb-20 sm:my-0': index % 2 === 0,
            'sm:-mt-20 sm:mb-20 lg:my-0': index % 3 !== 1
          }"
          :recipe="recipe"
          :showNutrition="showNutrition"
        />
      </div>
    </div>

  </div>
</template>

<script>
import RecipeTile from '@/components/RecipeTile2'
import http from '../http'
import store from '../store'

export default {
  components: {
    RecipeTile
  },
  data () {
    return {
      recipes: [],
      filter: '',
      filters: {
        meat: false,
        fish: false,
        lactose: false,
        veggie: false,
        gluten: false
      }
    }
  },
  mounted () {
    this.getRecipes()
  },
  computed: {
    showNutrition () {
      return store.showNutrition
    },
    filtered () {
      const filter = this.filter.trim().toLowerCase()

      if (!filter.length) return this.recipes

      return this.recipes.filter(recipe => {
        if (recipe.name.toLowerCase().includes(filter)) return true
        if (recipe.recipeIngredients.some(ingredient => {
          if (ingredient.nameSingular.toLowerCase().includes(filter)) return true
          if (ingredient.namePlural.toLowerCase().includes(filter)) return true
          if (ingredient.ingredient.singular.toLowerCase().includes(filter)) return true
          if (ingredient.ingredient.plural.toLowerCase().includes(filter)) return true
        })) return true
      })
    }
  },
  methods: {
    async getRecipes () {
      const res = await http.get('/api/public/recipes')

      this.recipes = res.data
    },
    toggleNutrition () {
      store.showNutrition = !store.showNutrition
    }
  }
}
</script>
